import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { Location } from 'grommet-icons';

class GoogleMap extends Component {

  render() {
    const sensors = this.props.sensors;

    var childLocations = []
    for (let i = 0; i < sensors.length; i++) {
      childLocations.push(
        <Location key={i} lat={sensors[i].lat} lng={sensors[i].lng} size='large' color={sensors[i].state ? '#E31925' : '#19E325'} /> 
      );
    }

    return (
      <GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyAfrBuRz6bLJMXt4kK3KOVrV42M_as35D0' }}
        center={this.props.center}
        zoom={this.props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onChange={this.props.onChange}
        onClick={this.props.onClick}
        options={this.props.options}>
        {childLocations}
      </GoogleMapReact>
    );
  }
};

GoogleMap.propTypes = {
  center: PropTypes.object.isRequired,
  sensors: PropTypes.array,
  zoom: PropTypes.number.isRequired,
  options: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default GoogleMap;
