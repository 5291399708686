import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  FormField,
  Box,
  Text,
  TextInput,
} from 'grommet';

import { Update } from 'grommet-icons';

import GoogleMap from './GoogleMap';
import { v4 as uuidv4 } from 'uuid';

const sugestions = ['apexit.pl', 'FortiNet', 'IoT', 'Cloud Computing'];

export default class SetSensor extends Component {

  constructor (props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);
    this._onSensorIdGenerate = this._onSensorIdGenerate.bind(this);
    this._onMapChange = this._onMapChange.bind(this);
    this._onMapClick = this._onMapClick.bind(this);

    this.state = {
      sensorId: this.props.sensorId,
      sensorState: this.props.sensorState,
      sensorLabel: this.props.sensorLabel,
      sensorType: 'f0f4371a-9b18-4755-aa89-d5a7152e6525',
      sensorLat: this.props.sensorLat,
      sensorLng: this.props.sensorLng
    };


    if (this.props.sensorId === '<sensor not registered>') {
      this.state.sensorId = uuidv4();
    } else {
      this.state.sensorId = this.props.sensorId;
    }
  }

  _onSubmit (e) {
    e.preventDefault();
    if (this.state.sensorId) {
      this.props.onSubmit({
        sensorId: this.state.sensorId,
        sensorLabel: this.state.sensorLabel,
        sensorLat: this.state.sensorLat,
        sensorLng: this.state.sensorLng
      });
    }
  }

  _onSensorIdGenerate () {
    this.setState({ sensorId: uuidv4() });
  }

  _onMapChange (e) {
    this.setState({ sensorLat: e.center.lat, sensorLng: e.center.lng });
  }

  _onMapClick (e) {
    this.setState({ sensorLat: e.lat, sensorLng: e.lng });
  }

  render () {
    return (
      <Box fill={true} direction='column' background='light-2' justify='between'>
          <Box direction='row' align='center' pad={{horizontal: 'none', vertical: 'small'}}>
            <FormField label="Sensor ID" htmlFor="sensorId" width='medium'>
              <TextInput id="sensorId" value={this.state.sensorId} onChange={(e) => this.setState({ sensorId: e.target.value })} />
            </FormField>
            <Button icon={<Update />} plain={true} onClick={this._onSensorIdGenerate} />
          </Box>
          <Box fill={true} flex={true} height='xlarge' pad='small'>
            <GoogleMap center={{lat: this.state.sensorLat, lng: this.state.sensorLng}} sensors={[{lat: this.state.sensorLat, lng: this.state.sensorLng, state: this.state.sensorState}]} zoom={18} options={{panControl: false, mapTypeControl: false, fullscreenControl: false, zoomControl: false, scaleControl: false, streetViewControl: false, rotateControl: false}} onClick={this._onMapClick}></GoogleMap>
          </Box>
          <Text margin='small' color='dark-4' size='small'>
            lat: {this.state.sensorLat} lng: {this.state.sensorLng} | id: {this.state.sensorId}
          </Text>
          <Box fill={false} flex={false} direction='row' align='center' pad='small' gap='small'>
            <Text weight='normal' size='medium'>Label:</Text>
            <TextInput id='sensorLabel' value={this.state.sensorLabel} suggestions={sugestions} onSuggestionSelect={(e) => this.setState({ sensorLabel: e.suggestion })} onChange={(e) => this.setState({ sensorLabel: e.target.value })} />
            <Button label='Set' type='submit' primary={true} onClick={this._onSubmit} />
            <Button label='Cancel' type='reset' onClick={this.props.onClose} />
          </Box>
      </Box>
    );
  }
}

SetSensor.propTypes = {

  sensorId: PropTypes.string.isRequired,
  sensorState: PropTypes.bool.isRequired,
  sensorLabel: PropTypes.string.isRequired,
  sensorLat: PropTypes.number.isRequired,
  sensorLng: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
