import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import {
  Grommet,
  ResponsiveContext,
  Anchor,
  Text,
  Box,
  Layer,
  Image,
  Button,
  CheckBox,
  Tag,
} from 'grommet';

import { FormClose, Menu } from 'grommet-icons';

import axios from 'axios';
import SetSensor from './components/SetSensor';
import GoogleMap from './components/GoogleMap';

const apexit = {
  global: {
    colors: {
      brand: '#E31925', // 
      focus: '#202835', // 32 40 53
      'accent-1': '#FFD157',
      'accent-2': '#0B5C99',
      'accent-3': '#EE8282',
      'accent-4': '#66126C',
      'neutral-1': '#EB303B',
      'neutral-2': '#231F20',
      'neutral-3': '#F3F3F3',
      'neutral-4': '#DCDCDC',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='focus'
    height='xxsmall'
    pad={{ left: 'small', right: 'small', vertical: 'xsmall' }}
    elevation='small'
    style={{ zIndex: '1' }}
    {...props}
  />
);

class DeviceDashboard extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  constructor (props) {
    super(props);

    this.state = {
      showSidebar: false,
      sensorId: this.props.cookies.get('sensorId') || '<sensor not registered>',
      sensorType: 'f0f4371a-9b18-4755-aa89-d5a7152e6525',
      sensorKey: '',
      sensorState: this.props.cookies.get('sensorState') === 'true' ? true : false,
      sensorLabel: this.props.cookies.get('sensorLabel') || 'apexit.pl',
      sensorLat: parseFloat(this.props.cookies.get('sensorLat')) || 52.17587131970686,
      sensorLng: parseFloat(this.props.cookies.get('sensorLng')) || 20.94535792111328,
    }

    this._onSetSensor = this._onSetSensor.bind(this);
    this._onSensorStateChange = this._onSensorStateChange.bind(this);
    this._sendState = this._sendState.bind(this);
  };

  componentDidMount() {
    const { sensorId } = this.state;

    if (!sensorId || sensorId === '' || sensorId === '<sensor not registered>') this.setState({showSidebar: true});
  };

  _onSetSensor (e) {
    const { cookies } = this.props;

    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 31);

    cookies.set('sensorId', e.sensorId, {path: '/', expires: expireDate});
    cookies.set('sensorLabel', e.sensorLabel, {path: '/', expires: expireDate});
    cookies.set('sensorLat', e.sensorLat, {path: '/', expires: expireDate});
    cookies.set('sensorLng', e.sensorLng, {path: '/', expires: expireDate});

    this.setState({showSidebar: false, sensorId: e.sensorId, sensorLabel: e.sensorLabel, sensorLat: e.sensorLat, sensorLng: e.sensorLng});
    
    this._sendState();
  };

  _onSensorStateChange () {
    const { cookies } = this.props;
    
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 31);
    cookies.set('sensorState', !this.state.sensorState, {path: '/', expires: expireDate});
    
    this.setState({sensorState: !this.state.sensorState});

    this._sendState();
  };

  _sendState () {
    axios.post('https://rest-collector.apexit.cloud/api/v1/json', {
      sensorId: this.state.sensorId,
      sensorType: this.state.sensorType,
      sensorState: this.state.sensorState,
      sensorLabel: this.state.sensorLabel,
      location: {
          type: "Point",
          coordinates: [this.state.sensorLng, this.state.sensorLat],
      },
      timestamp: Math.floor(Date.now() / 1000),
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render () {

    const {
      sensorId,
      sensorType,
      // sensorKey,
      sensorState,
      sensorLabel,
      sensorLat,
      sensorLng,

      showSidebar,
    } = this.state

    return (
      <Grommet theme={apexit} full>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box fill>
              <AppBar>
                <Anchor href='https://apexit.pl/'><Image src='logo.svg' height='28px'/></Anchor>
                <Text size='medium' weight='bold'>{(size === 'small') ? 'Demo | Device' : 'Demo | Device'}</Text>
                <Button icon={<Menu />} onClick={() => this.setState({ showSidebar: !this.state.showSidebar })} />
              </AppBar>
              <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                <Box flex pad='small' gap='small'>
                  <GoogleMap center={{lat: sensorLat, lng: sensorLng}} sensors={[{lat: sensorLat, lng: sensorLng, state: sensorState}]} zoom={18} options={{panControl: false, mapTypeControl: false, draggable: false, disableDoubleClickZoom: true, scrollwheel: false, fullscreenControl: false, zoomControl: true, scaleControl: false, streetViewControl: false, rotateControl: false}} />
                  <Box direction='column' align='start' pad={{horizontal: 'none', vertical: 'small'}} gap='medium'>
                    <Box direction='row' align='center' gap='medium'>
                      <Tag name="label" value={sensorLabel} />
                      <CheckBox id='sensorState' checked={this.state.sensorState} toggle={true} label='Sensor State' onChange={this._onSensorStateChange} />
                    </Box>
                    <Text margin='none' color='dark-4' size='small'>
                      lat: {sensorLat} lng: {sensorLng} | type: {sensorType} id: {sensorId}
                    </Text>
                  </Box>
                </Box>
                {showSidebar && (
                  <Layer full onEsc={() => this.setState({ showSidebar: false })}>
                    <Box height='xxsmall' background='brand' justify='between' align='center' direction='row' pad={{horizontal: 'small', vertical: 'small'}}>
                      <Text weight='bold' size='medium'>Sensor settings</Text>
                      <Button icon={<FormClose />} onClick={() => this.setState({ showSidebar: false })} />
                    </Box>
                    <SetSensor sensorId={sensorId} sensorState={sensorState}  sensorLabel={sensorLabel} sensorLat={sensorLat} sensorLng={sensorLng} onClose={() => this.setState({ showSidebar: false })} onSubmit={(e) => this._onSetSensor(e)} />
                  </Layer>
                )}
              </Box>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    )
  }
};

export default withCookies(DeviceDashboard);
